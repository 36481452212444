import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import * as moment from "moment/moment";
import { InventoryService } from "./inventory.service";
import { SettingsService } from "./settings.service";
import { SortService } from "../core/sort.service";
import { WorkOrderTypeService } from "./work-order-type.service";
import { Inventory, MonthMap, OpenTimeSlot, TimeSlotReaction, WorkOrder, WorkOrderType } from "../../interfaces";
import { TeamFilterService } from "../filter/team-filter.service";
import { ToastService } from "../core/toast.service";
import { ObjectService } from "../core/object.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root"
})
export class WorkOrderService {
  constructor(
    private api: ApiService,
    private inventoryService: InventoryService,
    private settingService: SettingsService,
    private sortService: SortService,
    private workOrderTypeService: WorkOrderTypeService,
    private teamFilterService: TeamFilterService,
    private toastService: ToastService,
    private objectService: ObjectService,
    private translate: TranslateService
  ) {}

  async publishDrafts(time: { startDay: string; endDay: string; Team?: string }): Promise<boolean> {
    return this.api.post("work-orders/draft", time);
  }
  async acceptTimeSlots(time: { startDay: string; endDay: string; Team?: string }): Promise<boolean> {
    return this.api.post("work-orders/accept-timeslots", time);
  }
  async unPublishDrafts(time: { startDay: string; endDay: string; Team?: string }): Promise<boolean> {
    return this.api.post("work-orders/draft/undo", time);
  }
  async resetChanges(time: { startDay: string; endDay: string; Team?: string }): Promise<boolean> {
    return this.api.post("work-orders/changes", time);
  }

  async import(workOrder: WorkOrder): Promise<WorkOrder> {
    if (!workOrder.ExternalId) {
      return workOrder;
    }
    return this.api.post("work-orders/import/" + workOrder.ExternalId, workOrder);
  }

  async importAll(workOrders: WorkOrder[]): Promise<boolean> {
    return this.api.post("work-orders/import/", { WorkOrders: workOrders });
  }

  async importAllAndDownloadFile(workOrders: WorkOrder[]): Promise<Blob> {
    return this.api.postFile("work-orders/import/file", {
      WorkOrders: workOrders
    });
  }

  async destroy(workOrder: WorkOrder, successMessage?: string): Promise<WorkOrder> {
    if (!workOrder.Id) {
      return workOrder;
    }
    return this.api.delete("work-orders/" + workOrder.Id, successMessage);
  }

  async save(workOrder: WorkOrder, successMessage?: string): Promise<WorkOrder> {
    const element: any = workOrder;
    if (element.QualificationIds && Array.isArray(element.QualificationIds)) {
      element.QualificationIds = JSON.stringify(element.QualificationIds);
    }
    if (element.Id) {
      return this.api.post("work-orders/" + element.Id, element, successMessage);
    }
    return this.api.post("work-orders", element, successMessage);
  }

  async get(id: string): Promise<WorkOrder> {
    const workOrder = await this.api.get("work-orders/" + id);
    if (workOrder) {
      if (workOrder.WorkOrderTypeId && !workOrder.WorkOrderType) {
        workOrder.WorkOrderType = await this.workOrderTypeService.get(workOrder.WorkOrderTypeId);
      }
      let startTime = workOrder.StartTime ? moment(workOrder.StartTime) : moment().add(2, "h").startOf("hour");
      let endTime = workOrder.EndTime
        ? moment(workOrder.EndTime)
        : moment(startTime).add(workOrder.WorkOrderType?.EstimatedDuration || 8 * 60, "minutes");

      workOrder.StartTime = startTime.toDate();
      workOrder.EndTime = endTime.toDate();
      workOrder.SpecialFields = workOrder.SpecialFields || {};
      workOrder.Tasks = workOrder.Tasks || [];
      workOrder.Triggers = workOrder.Triggers || [];
      workOrder.Inventories = workOrder.Inventories || [];
      if (typeof workOrder.QualificationIds === "string") {
        workOrder.QualificationIds = JSON.parse(workOrder.QualificationIds);
      }
      workOrder.QualificationIds = workOrder.QualificationIds || [];

      this.sortService.sortAscByFields(workOrder.Inventories, "Type", "Name");
    }
    return workOrder;
  }

  async getAll(params?: any, useCache: boolean = false): Promise<WorkOrder[]> {
    if (useCache) {
      return this.api.Cache("work-orders", []);
    }
    params = params || {};
    for (const key in params) {
      if (params[key] && typeof params[key] === "object") {
        params[key] = JSON.stringify(params[key]);
      }
    }
    return this.api.get("work-orders", params);
  }

  async getAllOpen(useCache: boolean = false): Promise<WorkOrder[]> {
    if (useCache) {
      return this.api.Cache("work-orders/open", []);
    }
    return this.api.get("work-orders/open");
  }

  async getAllByMonth(year: string | number, month: string | number): Promise<MonthMap> {
    return await this.api.get(`work-orders/time/${year}/${month}`, {
      DateMap: true
    });
  }

  async getAllOpenByWeek(year: number | string, week: number | string): Promise<{ [key: string]: OpenTimeSlot[] }> {
    const filter = {
      ...this.teamFilterService.getParams()
    };
    return this.api.get(`work-orders/open/${year}/${week}`, filter);
  }

  async getAllOpenUpcoming(days: number = 10): Promise<WorkOrder[]> {
    return this.api.get("work-orders/open/days/" + days);
  }

  async getAllReactions(workOrder: WorkOrder): Promise<TimeSlotReaction[]> {
    if (!workOrder.Id) {
      return [];
    }
    return this.api.get("work-orders/" + workOrder.Id + "/reactions");
  }

  async deleteInventory(workOrder: WorkOrder, inventory: Inventory): Promise<void> {
    await this.api.delete(`work-orders/inventory/${workOrder.Id}/${inventory.Id}`);
    await this.updateInventories(workOrder);
  }

  async saveInventory(workOrder: WorkOrder, inventory: Inventory): Promise<void> {
    await this.api.post("work-orders/inventory", {
      WorkOrderId: workOrder.Id,
      InventoryId: inventory.Id
    });
    await this.updateInventories(workOrder);
  }

  async updateInventories(workOrder: WorkOrder): Promise<void> {
    const inventories: Inventory[] = await this.api.get("work-orders/inventory/" + workOrder.Id);
    this.sortService.sortAscByFields(inventories, "Type", "Name");
    workOrder.Inventories = inventories || [];
  }

  async getInventorySelectionList(workOrder: WorkOrder): Promise<Inventory[]> {
    return this.api.get("work-orders/inventory/selection-list/" + workOrder.Id);
  }

  async showInventory(): Promise<boolean> {
    return this.inventoryService.show();
  }

  async showExternalApproval(): Promise<boolean> {
    return this.settingService.getBooleanValue("ExternalApproval");
  }

  async showImport(): Promise<boolean> {
    return this.settingService.getBooleanValue("WorkOrderImport");
  }

  async sendFormReminder(workOrder: WorkOrder): Promise<void> {
    if (workOrder.Draft) {
      return this.toastService.presentWarning(this.translate.instant("im_draft_werden_keine_benachrichtigungen"));
    }
    await this.api.post(
      "work-orders/push/trigger/" + workOrder.Id,
      {},
      this.translate.instant("die_ausstehenden_formulare_wurden_versendet")
    );
  }

  createOrUpdateFromType(workOrderType: WorkOrderType, workOrder: WorkOrder = {}): WorkOrder {
    workOrder.StartTime = workOrder.StartTime || moment().startOf("d").set({ hours: 8 }).toDate();
    workOrder.Triggers = workOrder.Triggers || [];
    workOrder.SpecialFields = workOrder.SpecialFields || {};

    if (workOrderType.WorkOrderStart && workOrderType.WorkOrderEnd) {
      const shiftStart = moment(workOrderType.WorkOrderStart);
      const duration = Math.abs(moment(workOrderType.WorkOrderEnd).diff(shiftStart, "minutes"));
      const startTime = moment(workOrder.StartTime).startOf("d").set({ hours: shiftStart.hours(), minutes: shiftStart.minutes() });
      const endTime = moment(startTime).add(duration != 0 ? duration : 1440, "minutes");
      workOrder.StartTime = moment(startTime).toDate();
      workOrder.EndTime = moment(endTime).toDate();
    }

    for (const key in workOrderType) {
      if (key === "Qualifications" || key === "CreatedAt" || key === "UpdatedAt") {
        continue;
      }
      if (key === "Id") {
        workOrder.WorkOrderTypeId = workOrderType.Id;
        continue;
      }
      if (key === "SpecialFields") {
        for (const specialField in workOrderType[key]) {
          if (this.objectService.isEmpty(workOrder[key][specialField])) {
            workOrder[key][specialField] = workOrderType[key][specialField];
          }
        }
        continue;
      }
      if (key === "Triggers" && workOrder.Triggers.length === 0) {
        for (const trigger of workOrderType.Triggers || []) {
          const copyTrigger = JSON.parse(JSON.stringify(trigger));
          delete copyTrigger.Id;
          delete copyTrigger.WorkOrderTypeId;
          workOrder.Triggers.push(copyTrigger);
        }
        continue;
      }
      if (key === "EstimatedDuration" && (workOrderType.EstimatedDuration || !workOrder.EndTime)) {
        workOrder.EndTime = moment(workOrder.StartTime)
          .add(workOrderType.EstimatedDuration || 0, "minutes")
          .toDate();
        continue;
      }
      if (this.objectService.isEmpty(workOrder[key])) {
        workOrder[key] = JSON.parse(JSON.stringify(workOrderType[key]));
      }
    }
    return workOrder;
  }

  async copy(source: WorkOrder): Promise<WorkOrder> {
    const successMessage = this.translate.instant("pages.work_order.work_order_detail.copySuccessMsg");
    const copy: any = JSON.parse(JSON.stringify(source));
    copy.CreatedAt = null;
    copy.DeletedAt = null;
    copy.UpdatedAt = null;
    copy.Id = null;
    copy.Name = copy.Name + " - " + this.translate.instant("kopie");
    if (copy.QualificationIds) {
      copy.QualificationIds = JSON.stringify(copy.QualificationIds);
    }
    copy.Triggers = copy.Triggers.map(trigger => {
      delete trigger.Id;
      return trigger;
    });
    return this.api.post("work-orders", copy, successMessage);
  }
}
