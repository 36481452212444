import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { InventoryService } from "./inventory.service";
import { SettingsService } from "./settings.service";
import { SortService } from "../core/sort.service";
import * as moment from "moment/moment";
import { ShiftTypeService } from "./shift-type.service";
import {
  Inventory,
  MonthMap,
  OpenTimeSlot,
  Shift,
  ShiftType,
  TimeSlot,
} from "../../interfaces";
import { TeamFilterService } from "../filter/team-filter.service";
import { ToastService } from "../core/toast.service";
import { ObjectService } from "../core/object.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class ShiftService {
  constructor(
    private api: ApiService,
    private inventoryService: InventoryService,
    private settingService: SettingsService,
    private sortService: SortService,
    private shiftTypeService: ShiftTypeService,
    private teamFilterService: TeamFilterService,
    private toastService: ToastService,
    private objectService: ObjectService,
    private translate: TranslateService,
  ) {}

  async publishDrafts(time: {
    startDay: string;
    endDay: string;
    Team?: string;
  }): Promise<boolean> {
    return this.api.post("shifts/draft", time);
  }
  async unPublishDrafts(time: {
    startDay: string;
    endDay: string;
    Team?: string;
  }): Promise<boolean> {
    return this.api.post("shifts/draft/undo", time);
  }
  async resetChanges(time: {
    startDay: string;
    endDay: string;
    Team?: string;
  }): Promise<boolean> {
    return this.api.post("shifts/changes", time);
  }

  async destroy(shift: Shift, successMessage?: string): Promise<Shift> {
    if (!shift.Id) {
      return shift;
    }
    return this.api.delete("shifts/" + shift.Id, successMessage);
  }

  async save(shift: Shift, successMessage?: string): Promise<Shift> {
    const element: any = shift;
    if (element.QualificationIds && Array.isArray(element.QualificationIds)) {
      element.QualificationIds = JSON.stringify(element.QualificationIds);
    }
    if (element.Id) {
      return this.api.save("shifts/" + element.Id, element, successMessage);
    }
    return this.api.save("shifts", element, successMessage);
  }

  async get(id: string): Promise<Shift> {
    const shift: Shift = await this.api.get("shifts/" + id);
    if (shift) {
      if (shift.ShiftTypeId && !shift.ShiftType) {
        shift.ShiftType = await this.shiftTypeService.get(shift.ShiftTypeId);
      }
      if (!shift.Street || !shift.City || !shift.Zip) {
        shift.Street = null;
        shift.City = null;
        shift.Zip = null;
      }

      let startTime = shift.StartTime
        ? moment(shift.StartTime)
        : moment().add(2, "h").startOf("hour");
      let endTime = shift.EndTime
        ? moment(shift.EndTime)
        : moment(startTime).add(
            shift?.ShiftType?.EstimatedDuration || 8 * 60,
            "minutes",
          );

      shift.StartTime = startTime.toDate();
      shift.EndTime = endTime.toDate();
      shift.Tasks = shift.Tasks || [];
      shift.Triggers = shift.Triggers || [];
      shift.SpecialFields = shift.SpecialFields || {};
      shift.Inventories = shift.Inventories || [];
      this.sortService.sortAscByFields(shift.Inventories, "Type", "Name");
    }
    return shift;
  }

  async getAll(params?: any, useCache: boolean = false): Promise<Shift[]> {
    if (useCache) {
      return this.api.Cache("shifts", []);
    }
    params = params || {};
    for (const key in params) {
      if (params[key] && typeof params[key] === "object") {
        params[key] = JSON.stringify(params[key]);
      }
    }
    return this.api.get("shifts", params);
  }

  async getAllOpenByWeek(
    year: string | number,
    week: string | number,
  ): Promise<{ [key: string]: OpenTimeSlot[] }> {
    const filter = {
      ...this.teamFilterService.getParams(),
    };
    return this.api.get(`shifts/open/${year}/${week}`, filter);
  }

  async getAllByMonth(
    year: string | number,
    month: string | number,
  ): Promise<MonthMap> {
    return await this.api.get(`shifts/time/${year}/${month}`, {
      DateMap: true,
    });
  }

  async deleteInventory(shift: Shift, inventory: Inventory): Promise<void> {
    await this.api.delete(`shifts/inventory/${shift.Id}/${inventory.Id}`);
    await this.updateInventories(shift);
  }

  async saveInventory(shift: Shift, inventory: Inventory): Promise<void> {
    await this.api.post("shifts/inventory", {
      ShiftId: shift.Id,
      InventoryId: inventory.Id,
    });
    await this.updateInventories(shift);
  }

  async updateInventories(shift: Shift): Promise<void> {
    const inventories: Inventory[] = await this.api.get(
      "shifts/inventory/" + shift.Id,
    );
    this.sortService.sortAscByFields(inventories, "Type", "Name");
    shift.Inventories = inventories || [];
  }

  async getInventorySelectionList(shift: Shift): Promise<Inventory[]> {
    return this.api.get("shifts/inventory/selection-list/" + shift.Id);
  }

  async showInventory(): Promise<boolean> {
    return this.inventoryService.show();
  }

  async showLocationTracking(): Promise<boolean> {
    return this.settingService.getBooleanValue("ShiftLocationTracking");
  }

  async sendFormReminder(shift: Shift): Promise<void> {
    if (shift.Draft) {
      return this.toastService.presentWarning(
        this.translate.instant('im_draft_werden_keine_benachrichtigungen'),
      );
    }
    await this.api.post(
      "shifts/push/trigger/" + shift.Id,
      {},
      this.translate.instant('die_ausstehenden_formulare_wurden_versendet'),
    );
  }

  createOrUpdateFromType(shiftType: ShiftType, shift: Shift = {}): Shift {
    shift.StartTime =
      shift.StartTime || moment().startOf("d").set({ hours: 8 }).toDate();
    shift.Triggers = shift.Triggers || [];
    shift.SpecialFields = shift.SpecialFields || {};

    if (shiftType.ShiftStart && shiftType.ShiftEnd) {
      const shiftStart = moment(shiftType.ShiftStart);
      const duration = Math.abs(
        moment(shiftType.ShiftEnd).diff(shiftStart, "minutes"),
      );
      const startTime = moment(shift.StartTime)
        .startOf("d")
        .set({ hours: shiftStart.hours(), minutes: shiftStart.minutes() });
      const endTime = moment(startTime).add(duration != 0 ? duration : 1440, "minutes");
      shift.StartTime = moment(startTime).toDate();
      shift.EndTime = moment(endTime).toDate();
    }

    for (const key in shiftType) {
      if (
        key === "Qualifications" ||
        key === "CreatedAt" ||
        key === "UpdatedAt"
      ) {
        continue;
      }
      if (key === "Id") {
        shift.ShiftTypeId = shiftType.Id;
        continue;
      }
      if (key === "SpecialFields") {
        for (const specialField in shiftType[key]) {
          if (this.objectService.isEmpty(shift[key][specialField])) {
            shift[key][specialField] = shiftType[key][specialField];
          }
        }
        continue;
      }
      if (key === "Triggers" && shift.Triggers.length === 0) {
        for (const trigger of shiftType.Triggers || []) {
          const copyTrigger = JSON.parse(JSON.stringify(trigger));
          delete copyTrigger.Id;
          delete copyTrigger.ShiftTypeId;
          shift.Triggers.push(copyTrigger);
        }
        continue;
      }
      if (this.objectService.isEmpty(shift[key])) {
        shift[key] = JSON.parse(JSON.stringify(shiftType[key]));
      }
    }
    return shift;
  }

  async copy(source: Shift): Promise<Shift> {
    const successMessage = this.translate.instant(
      "pages.shift.shift_detail.copySuccessMsg",
    );
    const copy: Shift = JSON.parse(JSON.stringify(source));
    copy.CreatedAt = null;
    copy.DeletedAt = null;
    copy.UpdatedAt = null;
    copy.Id = null;
    copy.Name = source.Name + " - Kopie";
    copy.Triggers = copy.Triggers.map((trigger) => {
      delete trigger.Id;
      return trigger;
    });
    return this.api.post("shifts", copy, successMessage);
  }
}
